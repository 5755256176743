import { type MaybeCanParse, roundToString, totalLineItem } from '@lib';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { useQuotePdfContext } from '@ui/pages/QuoteBuilder/hooks';
import { pdfStyles } from '@ui/pdfs/pdf-styles';
import { commaSeparated, currency } from '@ui/util';
import sortBy from 'lodash-es/sortBy';
import type { QuotePdfTemplateConfig } from '../config';
import { EstimatedQuantityOnHand } from './EstQtyOnHand';
import { PdfLineItemDetails } from './LineItemDetails';

type PdfLineItemProps = {
  lineIndex: number;
  config: QuotePdfTemplateConfig;
};

const pdfLineItemStyles = StyleSheet.create({
  wrapper: {
    borderTopWidth: '1px',
    borderTopColor: '#000000',
    borderTopStyle: 'dashed',
    padding: 10,
  },
  leftColumnWrapper: {
    minWidth: '33%',
    alignItems: 'flex-end',
    gap: 36,
    justifyContent: 'space-between',
  },
});

export const PdfLineItem: React.FC<PdfLineItemProps> = ({
  lineIndex,
  config,
}) => {
  const lineItem = useQuotePdfContext().getLineForm(lineIndex);
  const {
    description,
    leadTime,
    lineNumber,
    note,
    partId,
    quantity,
    quantityBreaks,
    unitPrice,
    drawing,
    drawingRevision,
    partOptions,
    finishedPartId,
  } = lineItem;

  const priceBreaks: {
    quantity: `${number}` | '';
    priceEach: `${number}` | '';
    lineTotal: `${number}` | '' | '-';
  }[] = [
    {
      quantity: roundToString(+quantity, {
        min: 0,
        small: 0,
      }),
      priceEach: roundToString(+unitPrice, {
        min: config.isRAF ? 3 : 2,
        small: config.isRAF ? 4 : 2,
      }),
      lineTotal: roundToString(+unitPrice * +quantity, {
        min: 3,
        small: config.isRAF ? 4 : 2,
      }),
    },
  ];

  for (const qb of quantityBreaks) {
    priceBreaks.push({
      quantity: roundToString(+qb.quantity, {
        min: 0,
        small: 0,
      }),
      priceEach: roundToString(+qb.unitPrice, {
        min: config.isRAF ? 3 : 2,
        small: config.isRAF ? 4 : 2,
      }),
      lineTotal: totalLineItem(+qb.unitPrice, +qb.quantity),
    });
  }

  const sortedPBs = sortBy(priceBreaks, (pb) => +pb.quantity);

  function formatCurrency(value: MaybeCanParse) {
    return currency(value, {
      min: config.isRAF ? 3 : 2,
      small: config.isRAF ? 4 : 3,
      separator: true,
    });
  }

  return (
    <View style={[pdfStyles.gap10, pdfStyles.row, pdfLineItemStyles.wrapper]}>
      <View style={pdfStyles.column}>
        <Text>Line Number {lineNumber.toString().padStart(2, '0')}</Text>
      </View>

      <View
        style={[
          pdfStyles.gap10,
          pdfStyles.column,
          { flex: 1, justifyContent: 'space-between' },
        ]}
      >
        <View>
          <Text style={[pdfStyles.bold, { paddingBottom: '4px' }]}>
            {finishedPartId}
          </Text>

          {config.showPartDescription && <Text>{description}</Text>}

          {partOptions?.map((option) => {
            if (option.name === 'None') {
              return null;
            }

            return (
              <Text
                key={option.id}
              >{`${option.groupName}: ${option.name}`}</Text>
            );
          })}
        </View>

        <Text>{note}</Text>

        {config.showQuantityOnHand && (
          <EstimatedQuantityOnHand lineIndex={lineIndex} />
        )}
      </View>

      <View style={[pdfStyles.column, pdfLineItemStyles.leftColumnWrapper]}>
        <PdfLineItemDetails
          drawing={drawing}
          drawingRevision={drawingRevision}
          leadTime={leadTime}
          config={config}
        />

        <View
          style={[
            pdfStyles.gap10,
            pdfStyles.row,
            pdfStyles.widthFull,
            { justifyContent: 'space-between' },
          ]}
        >
          <View style={[pdfStyles.alignEnd, pdfStyles.column, pdfStyles.gap4]}>
            <Text style={pdfStyles.underline}>Quantity</Text>
            {sortedPBs.map((pb) => (
              <Text key={`${lineNumber}-qty-${pb.quantity}`}>
                {commaSeparated(pb.quantity)}
              </Text>
            ))}
          </View>
          <View style={[pdfStyles.alignEnd, pdfStyles.column, pdfStyles.gap4]}>
            <Text style={pdfStyles.underline}>Price Each</Text>
            {sortedPBs.map((pb) => (
              <Text key={`${lineNumber}-price-${pb.priceEach}`}>
                {formatCurrency(pb.priceEach)}
              </Text>
            ))}
          </View>
          <View style={[pdfStyles.alignEnd, pdfStyles.column, pdfStyles.gap4]}>
            <Text style={pdfStyles.underline}>Line Total</Text>
            {sortedPBs.map((pb) => (
              <Text key={`${lineNumber}-total-${pb.lineTotal}`}>
                {formatCurrency(pb.lineTotal)}
              </Text>
            ))}
          </View>
        </View>
      </View>
    </View>
  );
};
