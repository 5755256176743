import { type MaybeCanParse, maybeRoundToString } from '@lib';
import { commaSeparated } from './numbers';

export const currency = (
  number: MaybeCanParse,
  config?: {
    min?: number;
    small?: number;
    separator?: boolean;
  },
): string => {
  const rounded = maybeRoundToString(
    number,
    {
      min: config?.min ?? 2,
      small: config?.small ?? 3,
    },
    '',
  );

  if (rounded === '') {
    return '';
  }

  const formatted = commaSeparated(rounded, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 3,
    useGrouping: config?.separator ?? false,
  });

  return `$${formatted}`;
};
