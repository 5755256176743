import {
  Box,
  Button,
  Flex,
  HStack,
  Spacer,
  type ToastId,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { faFilePdf, faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  type CalcPartHistoryConfigsInput,
  type CostCalculations,
  type CustomerHeaderResponse,
  ImmutableQuoteStates,
  MinQuantityCalculator,
  type PartResponse,
  PricingCalculatorFactory,
  type QuoteLineCreateRequest,
  type QuoteLineResponse,
  type QuoteResponse,
  ValidationError,
  calcOrderLine,
  calcPartHistory,
  calcQuoteLine,
  makeLastWinCalcsValidation,
  makeNegotiatedQuotingConfig,
  makePartHistoryValidation,
  qbLifecycleValidateOnAddLine,
  qbLifecycleValidateOnLoadQuote,
} from '@lib';
import { LeadTimeCalculator } from '@lib/calculations/lead-time';
import type { Inventory } from '@lib/models/inventory';
import { ConfigEntities, PricingStrategy } from '@prisma/client';
import { Document, usePDF } from '@react-pdf/renderer';
import is from '@sindresorhus/is';
import { useQueryClient } from '@tanstack/react-query';
import { RoutesConfig, route } from '@ui/config/routes';
import { quoteQueueSearchQueryKeyPrefix } from '@ui/data/quote/queue';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import type { MergeDeep } from 'type-fest';
import { DeleteConfirmButton } from '../../components/DeleteConfirmButton';
import Loading from '../../components/Loading';
import { QuoteStatusBadge } from '../../components/QuoteStatusBadge';
import { PageFooter } from '../../components/layout/PageFooter';
import PageHeader from '../../components/layout/PageHeader';
import { useLocalization } from '../../config/localization/getLocalization';
import { useActiveSiteConfig, useGlobalState, useSafeAsync } from '../../hooks';
import {
  negotiatedPriceService,
  partService,
  quoteService,
} from '../../services';
import {
  type LinePropsBase,
  QuoteLineContextProvider,
} from '../../state/QuoteLineContextProvider';
import { useAuth } from '../../state/auth';
import { monthDayYear } from '../../util/dates';
import { CustomerHeader } from './CustomerHeader';
import { PartPicker } from './PartPicker';
import { QuoteLineItem } from './QuoteLineItem';
import { QuotePdfDocument } from './QuotePdfDocument';
import { QuotePDFModal } from './QuotePdfModal';
import type { FormDocument } from './components/actions/DocumentsModal';
import { prepCostFormInputs } from './history-helpers';
import { useValidationToast } from './hooks/useToast';
import type { HistAndCalcs } from './types';
import { makePartHistoryUIManager } from './validation/part-history-manager';

export type LinePropsMap = {
  [partId: string]: LinePropsBase;
};

type LineCalcVals = {
  contributionMarginPercent?: number;
  grossMarginPercent?: number;
  unitCosts?: CostCalculations;
  totalCosts?: CostCalculations;
};

interface LineFormCalcVals extends LineCalcVals {
  quantityBreaks: LineCalcVals[];
  drawing?: string | null;
  drawingRevision?: string | null;
  inventory?: Inventory | null;
  documents: FormDocument[];
}
export type QuoteFormLine = MergeDeep<
  QuoteLineCreateRequest,
  LineFormCalcVals,
  { recurseIntoArrays: true }
>;

export interface QuoteBuilderForm {
  id: string;
  /** READABLE Quote.quoteId */
  quoteId: string;
  csrOid: string | null;
  csrName: string | null;
  csrEmail: string | null;
  notes: string;
  internalNotes: string | null;
  sendToCustomer: boolean;
  daysUntilExpiration: number;
  daysUntilExpectedWin: number;
  salesRepId: string | null;
  salesRepName: string | null;
  salesRepEmail: string | null;
  salesRepPhone: string | null;
  customerHeader: CustomerHeaderResponse;
  lineItems: QuoteFormLine[];
}

const { quoteFeedback, quoteNew, quoteQueue, quoteView } = RoutesConfig;

type QuoteBuilderProps = {
  quoteUUID: string;
};

export const QuoteBuilder = ({ quoteUUID }: QuoteBuilderProps) => {
  // TODO: track calcs & any fields not specifically in the form to be passed
  // to QuoteLine in a Map
  //   - useEffect to hook into that change which triggers the rhf append

  const queryClient = useQueryClient();
  const initialized = useRef(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [saveErrorToastId, setSaveErrorToastId] = useState<ToastId | null>(
    null,
  );
  const activeQuoteRef = useRef<QuoteResponse | null>(null);
  // TODO: temp non-null assertion since they are both gated in wrappers but
  //  will be handled gracefully & typed at the provider level soon
  const { user: _user, currentSite: _currentSite } = useAuth();
  const user = _user!;
  const currentSite = _currentSite!;

  const {
    siteConfig,
    pricingConfig: sitePricingConfig,
    quotingConfig: siteQuotingConfig,
  } = useActiveSiteConfig();

  const [activeQuote, setActiveQuote] = useState<QuoteResponse | null>(null);
  const followUpAddQuote = useRef(false);
  const { setActiveModal } = useGlobalState();
  const navigate = useNavigate();
  const qb_i18n = useLocalization('QuoteBuilder');
  const modal_i18n = useLocalization('Modal');
  const { safeAsyncHandler, cancelPromises } = useSafeAsync();
  const form = useForm<QuoteBuilderForm>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
    defaultValues: {
      daysUntilExpiration: siteQuotingConfig.daysUntilExpiration,
      daysUntilExpectedWin: siteQuotingConfig.daysUntilExpectedWin,
      id: quoteUUID,
    },
  });
  const [linePropMap, setLinePropMap] = useState<LinePropsMap>({});
  const {
    fields: quoteLineFields, // not the same reference between renders, careful with closures
    remove: removeLineItem,
    append: appendLineItem,
    replace: replaceLineItems,
  } = useFieldArray({
    name: 'lineItems',
    control: form.control,
  });

  const {
    phErrorToast,
    genericErrorToast,
    genericWarningToast,
    genericSuccessToast,
    validationErrorToast,
    clearAllToasts,
    clearToast,
  } = useValidationToast();
  const toast = useToast();
  const {
    isOpen: isPDFPreviewOpen,
    onOpen: onPDFPreviewOpen,
    onClose: onPDFPreviewClose,
  } = useDisclosure();

  // Load the quote
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;

      if (!quoteUUID) {
        return navigate(route(quoteQueue));
      }

      // Reload the quote if the URL has changed
      if (!activeQuote?.id || activeQuote.id !== quoteUUID) {
        (async () => {
          const quote = await quoteService.getQuote(quoteUUID);

          if (ImmutableQuoteStates.includes(quote.status)) {
            return navigate(route(quoteView, { id: quote.id }));
          }

          // TODO: use live Customer data + updates & CustomerContact picker +
          //  updates/create new when actually building the quote instead of
          //  JSON. Only need to save the json header when we submit for
          //  reference

          // Apply customer association fields as defaults
          quote.customerHeader = {
            ...quote.customer,
            name: quote.customer?.name, // name needs to be set explicitly in case of changes since we are using JSON
            ...quote.customerHeader,
          };

          setActiveQuote(quote);
          processExistingQuote(quote);
        })();
      }
    }

    return () => {
      // clear validation toasts
      clearAllToasts();
    };
  }, [quoteUUID]);

  // Redirect if quote's site doesn't match currently selected site
  useEffect(() => {
    if (activeQuote && currentSite && activeQuote.siteId !== currentSite.code) {
      return navigate(route(quoteQueue));
    }
  }, [currentSite, activeQuote, navigate]);

  // Process quote
  useEffect(() => {
    if (activeQuoteRef.current === null && activeQuote) {
      processExistingQuote(activeQuote);
    }

    activeQuoteRef.current = activeQuote;
  }, [activeQuote]);

  const [pdfInstance, updatePdfInstance] = usePDF();

  const handleReset = () => {
    initialized.current = false;
    activeQuoteRef.current = null;
    setActiveQuote(null);
    replaceLineItems([]);
    form.reset();
    cancelPromises();
  };

  const getPartHistoryAndCalcs = async (
    part: PartResponse,
  ): Promise<HistAndCalcs | undefined> => {
    if (!activeQuote) {
      return;
    }

    // Part history
    const safeGetPartHistory = safeAsyncHandler(partService.getPartHistory);
    const partHistoryProm = safeGetPartHistory(part.partId);

    // Negotiated pricing
    const safeGetNegotiatedPrice = safeAsyncHandler(
      negotiatedPriceService.getNegotiatedPrice,
    );
    const negotiatedPriceProm = safeGetNegotiatedPrice({
      partId: part.partId,
      customerId: activeQuote.customerId,
    });

    // Part history and negotiated pricing calls are independent so we can run
    // them concurrently
    const [partHistory, negotiatedPrice] = await Promise.all([
      partHistoryProm,
      negotiatedPriceProm,
    ]);

    // partHistory will be undefined if the promise was cancelled
    if (partHistory === undefined) {
      genericErrorToast('Unexpected error getting part history');
      return;
    }
    // negotiated pricing will be undefined if the promise was cancelled
    if (negotiatedPrice === undefined) {
      genericErrorToast(
        'Unexpected error getting negotiated pricing',
        'Verify pricing manually before sending quote',
        true,
      );

      return;
    }

    const partHistoryValidation = makePartHistoryValidation(
      {
        ...partHistory,
        negotiatedPrice: negotiatedPrice ?? null,
      },
      activeQuote?.id,
      sitePricingConfig.pricingStrategy,
    );

    const { data: validatedPartHistory } = partHistoryValidation;
    const { lastWin, mostRecentQuoteLine, mostRecentQuote } =
      validatedPartHistory;
    const entityConfigs: CalcPartHistoryConfigsInput = {
      siteConfig,
      quotingConfigs: {
        [ConfigEntities.SITE]: siteQuotingConfig,
        [ConfigEntities.PART]: negotiatedPrice?.defaultPrice
          ? makeNegotiatedQuotingConfig(
              negotiatedPrice.defaultPrice,
              `${quoteUUID}:${part.id}:negotiated-price`,
              siteQuotingConfig,
            )
          : undefined,
      },
    };

    const partHistCalcs = calcPartHistory(validatedPartHistory, entityConfigs, {
      customerHeader: form.getValues('customerHeader'),
    });
    const partHistoryMgr = makePartHistoryUIManager(
      partHistoryValidation,
      part.partId,
      partHistCalcs,
    );

    let lastWinCalcs = null;
    if (partHistoryMgr.valid && partHistoryMgr?.partHistory?.lastWin) {
      lastWinCalcs = calcOrderLine(partHistoryMgr.partHistory.lastWin);
    }

    //  const { lastOrder: lastWinCalcs } = partHistCalcs;
    const lastWinCalcValidation = makeLastWinCalcsValidation(lastWinCalcs);
    const mergedMgr = partHistoryMgr.addLastWinCalcsValidation(
      lastWinCalcValidation,
    );

    phErrorToast(mergedMgr);

    // TODO: update validation logic for new costing
    // valid means we can suggest a price

    //TODO: new validation logic if we can suggest a price
    //  lastWinCalcValidation.valid &&
    //    ({
    //      pricing: { suggestedPrice: calcPrice },
    //    } = lastWinCalcValidation.data);

    const lastQuoteCalcs =
      mostRecentQuoteLine && calcQuoteLine(mostRecentQuoteLine); // TODO: Should live in ph calcs

    const ret: HistAndCalcs = {
      // calcPrice,
      lastWin,
      lastWinCalcs: lastWinCalcs && {
        contributionMarginPercent:
          lastWinCalcs.margins.contributionMarginPercent,
        grossMarginPercent: lastWinCalcs.margins.grossMarginPercent,
        unitPrice: lastWinCalcs.pricing.unitPrice,
        orderQuantity: lastWinCalcs.orderQuantity,
        leadTimeWeeks: lastWinCalcs.leadTimeWeeks,
        leadTimeDays: lastWinCalcs.leadTimeDays,
      },
      partHistoryCalcs: partHistCalcs,
      partHistory: validatedPartHistory,
      mergedMgr,
      negotiatedPrice,
      mostRecentQuoteLine:
        (mostRecentQuoteLine && {
          ...mostRecentQuoteLine,
          calcs: lastQuoteCalcs ?? null, // TODO: decide
          quoteStartDate: mostRecentQuote?.startDate ?? null,
        }) ??
        null,
    };

    return ret;
  };

  const handlePartSelection = async (part: PartResponse) => {
    if (!activeQuote?.id) {
      genericErrorToast('Unable to find active quote');

      return;
    }

    let histAndCalcs: HistAndCalcs | undefined;

    try {
      histAndCalcs = await getPartHistoryAndCalcs(part);
    } catch (error) {
      console.error('Error getting part history', { error, part });

      genericErrorToast(
        'Unexpected error getting part history. Adding part without calculations.',
        error instanceof Error ? error?.message : '',
        true,
      );
    }
    const {
      lastWinCalcs,
      lastWin,
      partHistory,
      partHistoryCalcs,
      mergedMgr,
      negotiatedPrice,
      //   calcPrice,
      mostRecentQuoteLine,
    } = histAndCalcs ?? {};

    const { lineItems } = form.getValues();

    const {
      pricingConfigConsolidated = sitePricingConfig, // default used on ph api error TODO: extract consolidation logic
      resolvedQuotingInput,
      resolvedCostCalcs,
      resolvedCostInputs,
    } = partHistoryCalcs?.suggestions ?? {};

    const { laborCost, materialCost, burdenCost, serviceCost } =
      resolvedCostCalcs?.unit ?? {};

    // TODO(bb): make ph calcs always defined
    const basisQtys = partHistoryCalcs?.suggestions.suggestedQtys ?? [
      +(lastWin?.orderQuantity ?? 1),
    ];

    const marketSiteRequirements = MinQuantityCalculator.calculateRequirement(
      currentSite,
      partHistoryCalcs?.marketPricing.codeMap ?? null,
      form.getValues('customerHeader.marketCode'),
    );

    const { requiredQuantity } = marketSiteRequirements;

    let [mainQty, ...qtyBrkQtys] = basisQtys;
    mainQty = Math.max(mainQty, requiredQuantity);
    qtyBrkQtys = qtyBrkQtys.filter((brk) => brk > mainQty);

    let suggestedPrice: number | null = null;

    try {
      const pricingCalculator = PricingCalculatorFactory(
        pricingConfigConsolidated,
        {
          site: currentSite,
          partHistory,
          partHistoryCalcs,
          quoteData: form.getValues(),
        },
      );
      suggestedPrice = pricingCalculator.calcInitPrice(mainQty);
    } catch (error) {
      if (error instanceof ValidationError) {
        validationErrorToast(error);
      } else {
        genericErrorToast(
          `Unexpected error suggesting intial price for part ${part.id}`,
          error instanceof Error ? error?.message : '',
        );
        console.error(
          'QuoteBuilder.handlePartSelection: Unknown error thrown from pricing calculator',
          {
            error,
            part,
            quoteForm: form.getValues(),
          },
        );
      }
    }

    const leadTime = LeadTimeCalculator.calculateDays(currentSite, {
      lastWinCalcs,
      part,
    });

    const newQuoteLine: QuoteLineCreateRequest = {
      quoteId: activeQuote.quoteId,
      lineNumber: lineItems.length + 1,
      partId: part.partId,
      description: part.description,
      // finishedPartId defaults to partId until partOptions are selected
      finishedPartId: part.partId,
      quantity: mainQty,
      unitPrice: suggestedPrice ?? '',
      baseUnitPrice: suggestedPrice,
      unitBurdenCost: burdenCost,
      unitLaborCost: laborCost,
      unitMaterialCost: materialCost,
      unitServiceCost: serviceCost,
      automatedUnitPrice: suggestedPrice,
      historicalOrderLineId: lastWin?.id,
      historicalMasterId: partHistoryCalcs?.masterPrime?.masterEng?.id,
      // TODO: make new quoteLine config from resolved (can persist as 1-length
      //  configs to avoid creating an additional schema model)
      // quotingConfigId: resolvedQuotingConfig?.id,
      quantityBreaks: qtyBrkQtys.map((qty, breakNumber) => {
        const pricingCalculator = PricingCalculatorFactory(
          pricingConfigConsolidated,
          {
            site: currentSite,
            partHistory,
            partHistoryCalcs,
            quoteData: form.getValues(),
          },
        );
        suggestedPrice = pricingCalculator.calcInitPrice(qty);
        return {
          breakNumber: breakNumber + 1,
          quantity: qty,
          unitPrice: suggestedPrice ?? '',
          baseUnitPrice: suggestedPrice,
        };
      }),
      leadTime,
      partOptions: [],
    };

    const newQlCalcs = calcQuoteLine({
      ...newQuoteLine,
      operationCosts: resolvedCostInputs,
    });

    const {
      contributionMarginRatio: newQlContrMarginRatio,
      contributionMarginPercent: newQlContrMarginPct,
      grossMarginPercent: newQlGrossMarginPct,
      grossMarginRatio: newQlGrossMarginRatio,
    } = newQlCalcs.margins ?? {};

    // TODO: last win & most recent ql calcs are in ph calcs, no need to
    //  duplicate and drill everywhere
    setLinePropMap((prev) => {
      return {
        ...prev,
        [newQuoteLine.partId]: {
          part,
          partHistory,
          partHistoryCalcs: partHistoryCalcs,
          lastWinCalcs,
          lineManager: mergedMgr,
          mostRecentQuoteLine,
          negotiatedPrice,
        },
      };
    });
    appendLineItem({
      ...newQuoteLine,
      automatedMargin:
        pricingConfigConsolidated.pricingStrategy ===
        PricingStrategy.TARGET_CONTR_MARGIN
          ? newQlContrMarginRatio
          : newQlGrossMarginRatio,
      automatedLeadTime: newQuoteLine.leadTime,
      automatedQuantity: mainQty,
      contributionMarginPercent: newQlContrMarginPct,
      grossMarginPercent: newQlGrossMarginPct,
      operationCosts:
        resolvedCostInputs && prepCostFormInputs(resolvedCostInputs),
      documents: [],
    });

    const validationResults = qbLifecycleValidateOnAddLine({
      partHistory: partHistory,
      previousPrice: Number(lastWin?.unitPrice),
      newPrice: Number(newQuoteLine.unitPrice),
      siteCode: currentSite?.code,
    });

    for (const w of validationResults) {
      if (w.message) {
        genericWarningToast(
          `Warning for part: ${newQuoteLine.partId}`,
          w.message,
          true,
        );
      }
    }

    if (!followUpAddQuote.current) {
      followUpAddQuote.current = true;
    }
    // This time the modal should display the follow up message
    showAddPartModal();
    onSubmit(true);
  };

  const handleExistingLineItem = async (
    quoteLineResp: QuoteLineResponse,
  ): Promise<(LinePropsBase & { line: QuoteFormLine }) | undefined> => {
    const {
      part,
      historicalOrderLine,
      quantity,
      unitPrice,
      documents,
      ...restQlResp
    } = quoteLineResp; //TODO: quote/quote line route prob shouldn't return part + historicalOrderLine since we get them from part-history
    if (
      form
        .getValues()
        .lineItems.find(
          (line) => line.partId === part.id || line.id === quoteLineResp.id,
        )
    ) {
      return;
    }
    let histAndCalcs: HistAndCalcs | undefined;
    try {
      histAndCalcs = await getPartHistoryAndCalcs(part);
      if (!histAndCalcs) {
        return;
      }
    } catch (error) {
      console.error('Error getting part history', error);
      genericErrorToast(
        'Unexpected error getting part history',
        error instanceof Error ? error?.message : '',
        true,
      );
    }
    const {
      lastWinCalcs,
      lastWin,
      partHistory,
      partHistoryCalcs: partHistCalcs,
      mergedMgr,
      negotiatedPrice,
      // calcPrice,
      mostRecentQuoteLine,
    } = histAndCalcs ?? {};

    const { suggestions } = partHistCalcs ?? {};

    const quoteLineParsedInput: QuoteLineCreateRequest = {
      ...restQlResp,
      // TODO: abstract serializing/deserializing default/invalid values or just
      // make them optional in the db if that's what we're doing dangerously anyways
      quantity: Number(quantity) <= 0 ? '' : quantity,
      unitPrice: Number(unitPrice) <= 0 ? '' : unitPrice,
      historicalOrderLineId: lastWin?.id || quoteLineResp.historicalOrderLineId,
      historicalMasterId: partHistCalcs?.masterPrime?.masterEng?.id,
    };

    const qlCalcs = calcQuoteLine({
      ...quoteLineParsedInput,
      operationCosts: quoteLineParsedInput.operationCosts,
    });

    const {
      operationCosts, // should be the same as input here, but in other cases it does get changed in calcs so always use just in case
      margins,
    } = qlCalcs;

    // recalc qb's
    quoteLineParsedInput.quantityBreaks =
      quoteLineParsedInput.quantityBreaks.map((qb) => {
        const qbCalcs = calcQuoteLine({
          ...qb,
          operationCosts: quoteLineParsedInput.operationCosts,
        });

        const { contributionMarginPercent, grossMarginPercent } =
          qbCalcs.margins ?? {};
        return { ...qb, contributionMarginPercent, grossMarginPercent };
      });
    const lineProps: LinePropsBase = {
      part,
      partHistory,
      lastWinCalcs,
      lineManager: mergedMgr,
      mostRecentQuoteLine,
      partHistoryCalcs: partHistCalcs,
      negotiatedPrice,
    };

    const { contributionMarginPercent, grossMarginPercent } = margins ?? {};

    const ret = {
      ...lineProps,
      line: {
        ...quoteLineParsedInput,
        contributionMarginPercent,
        grossMarginPercent,
        operationCosts,
        documents,
      },
    };

    // not sure exactly why it would happen currently, but just in case, if
    // an existing line item has no (null) operation costs but we do have
    // from part hist, we can attach those
    const { resolvedCostInputs: pickedCostInputs } = suggestions ?? {};
    if (!ret.line.operationCosts && pickedCostInputs) {
      ret.line.operationCosts = pickedCostInputs;
    }

    return ret;
  };

  const reassignLineNumbers = () => {
    const { lineItems } = form.getValues();
    lineItems.forEach((li, i) => {
      form.setValue(`lineItems.${i}.lineNumber`, i + 1);
    });
  };

  const showAddPartModal = () => {
    if (!activeQuote) {
      genericErrorToast(
        'Unexpected error opening part picker:',
        'No active quote',
      );

      return;
    }

    const title = followUpAddQuote.current
      ? modal_i18n('partSearchFollowUpSelection')
      : modal_i18n('partSearchSelection');

    setActiveModal({
      title,
      onClose: () => {
        followUpAddQuote.current = false;
      },
      children: (
        <PartPicker
          onSelection={handlePartSelection}
          customerId={activeQuote.customerId}
        />
      ),
    });
  };

  const processExistingQuote = async (quote: QuoteResponse) => {
    try {
      if (!quote.lineItems || !Array.isArray(quote.lineItems)) {
        return;
      }

      // get existing line items concurrently and filter out undefined
      const lineItemProps = (
        await Promise.all(
          quote.lineItems.map((li) => handleExistingLineItem(li)),
        )
      ).filter(is.truthy);

      // this isn't really necessary since the db should be sorted and the promise array
      // maintains order, but doesn't hurt as a backup
      lineItemProps
        .sort((a, b) => Number(a.line.lineNumber) - Number(b.line.lineNumber))
        .forEach((props, i) => {
          const { operationCosts } = props.line;

          lineItemProps[i].line.operationCosts =
            operationCosts && prepCostFormInputs(operationCosts);

          setLinePropMap((prev) => {
            const { line, ...restProps } = props;
            return {
              ...prev,
              [line.partId]: restProps,
            };
          });
        });

      replaceLineItems(lineItemProps.map((props) => props.line));

      form.setValue('quoteId', quote.quoteId);
      form.setValue('internalNotes', quote.internalNotes);
      form.setValue('csrOid', quote.csrOid);
      form.setValue('csrName', quote.csrName);
      form.setValue('csrEmail', quote.csrEmail);
      form.setValue('salesRepId', quote.salesRepId);
      form.setValue('salesRepName', quote.salesRepName);
      form.setValue('salesRepEmail', quote.salesRepEmail);
      form.setValue('salesRepPhone', quote.salesRepPhone);

      const validationResults = qbLifecycleValidateOnLoadQuote({
        numberOfQuoteLines: quote.lineItems.length,
        siteCode: currentSite?.code,
      });

      for (const w of validationResults) {
        if (w.message) {
          genericWarningToast('Warning', w.message, true);
        }
      }

      // need to abstract some of handlePartSelection so we can load part history
      // and setup the same field array
    } catch (err: unknown) {
      genericErrorToast('Quote does not exist');
      navigate(route(quoteQueue));
    }
  };

  const onSubmit = useCallback(
    async (silent = false) => {
      setIsSaving(true);
      clearToast(saveErrorToastId);
      try {
        const { code: siteId } = currentSite;
        const {
          customerHeader,
          lineItems: quoteLines,
          notes,
          internalNotes,
          csrOid,
          csrName,
          csrEmail,
          salesRepId,
          salesRepEmail,
          salesRepName,
          salesRepPhone,
        } = form.getValues();
        const { customerId } = customerHeader;

        const sanitizeLineItem = (
          line: QuoteFormLine,
        ): QuoteLineCreateRequest => {
          const {
            unitPrice,
            baseUnitPrice,
            quantity,
            contributionMarginPercent, // TODO: we should strip extra props
            //  with a validator, TS doesn't have an easy way to prevent extra fields
            //  but prisma will throw a runtime error if we aren't careful
            grossMarginPercent,
            partId, // TODO: ^^ we have zod schemas now just not a priority to link up; this will keep happening though
            quantityBreaks,
            quotingConfigHistoryIds,
            quotingConfigId,
            rafFinishCode,
            drawing,
            drawingRevision,
            inventory,
            documents,
            ...restLine
          } = line;
          // TODO (jj): a better way to do this by redoing quantity break management in general
          const cleanQtyBreaks = line.id
            ? quantityBreaks.map((qb) => {
                if (Number.isNaN(+qb.quantity)) {
                  throw new Error('Cannot submit empty quantity breaks');
                }
                const {
                  contributionMarginPercent,
                  grossMarginPercent,
                  ...restQb
                } = qb;
                return restQb;
              })
            : [];
          // !TODO(bb): Are we still using this rafFinishCode stuff?
          let rafFinishDescription: string | null = null;

          if (rafFinishCode) {
            const finish = linePropMap[
              partId
            ]?.partHistory?.options?.finishes?.find(
              ({ pl_code }) =>
                !is.null(pl_code) && pl_code.toString() === rafFinishCode,
            );

            if (finish?.pl_code) {
              rafFinishDescription = `Finish: ${finish.pl_code
                .toString()
                .padStart(2, '0')} - ${finish.pl_desc}`;
            }
          }

          return {
            ...restLine,
            partId,
            unitPrice: Number(unitPrice) || -1,
            baseUnitPrice: Number(baseUnitPrice) || null,
            quantity: Number(quantity) || -1,
            quantityBreaks: cleanQtyBreaks,
            rafFinishCode,
            rafFinishDescription,
          };
        };

        const cleanLineItems = quoteLines.map(sanitizeLineItem);

        const quoteValue = await quoteService.updateQuote(quoteUUID, {
          customerId,
          siteId,
          customerHeader,
          lineItems: cleanLineItems,
          notes,
          internalNotes,
          csrOid,
          csrName,
          csrEmail,
          salesRepId,
          salesRepEmail,
          salesRepName,
          salesRepPhone,
        });

        /**
         * Invalidate the query cache (lazily) so that updates to this quote will
         * be reflected in the quote queue.
         *
         * @TODO(shawk): remove this once we move the mutations in quote builder
         * to react-query, and make sure that those RQ mutations invalidate the
         * necessary cache keys.
         */
        queryClient.invalidateQueries({
          queryKey: quoteQueueSearchQueryKeyPrefix,
        });

        /*  TODO: This doesn't make sense. If it's about conflicts if lines were
      edited/added/deleted in another window/by diff user, we should just
      replace the entire quote if it differs (compare hash with what we sent and
      got back) to have the freshest data that will actually be saved, sent to
      visual/customer, and pdf generated from fully up-to-date data. But also in
      that case this will just incorrectly set the the other window/user's line
      id to our old line's data, potentially even for a completely different
      part and ui state will not match our db & visual etc. Also using
      `setValue` doesn't trigger a state update so even if you spotted something
      weird in the pdf like a new line added, the ui itself will not be updated
      unless you refresh the entire page. Should be able to just replace the
      whole line array at a minimum, but really should update the whole quote.
      Needs discussion around conflict resolution strategy i.e merge (sort of
      what seems like current is attempting?) and def should at least inform the
      user of a conflict, most recent update wins for the complete quote object
      (incl api logic to replace all lines, and no need to await the update
      above), etc */
        if (quoteValue.lineItems) {
          const { lineItems } = form.getValues();
          for (const li of quoteValue.lineItems) {
            const oldLineNum = lineItems.findIndex(
              (line) => line.lineNumber === li.lineNumber,
            );
            form.setValue(`lineItems.${oldLineNum}.id`, li.id);
            if (li.quantityBreaks.length) {
              for (const qb of li.quantityBreaks) {
                const oldQb = li.quantityBreaks.findIndex(
                  (qtyBrk) => qb.breakNumber === qtyBrk.breakNumber,
                );
                form.setValue(
                  `lineItems.${oldLineNum}.quantityBreaks.${oldQb}.id`,
                  qb.id,
                );
              }
            }
          }
        }
        //TODO: this doesn't actually update anything on the ui and we still have a ui vs
        //db mismatch per above comment
        setActiveQuote(quoteValue);

        if (!silent) {
          genericSuccessToast('Quote saved');
        }
      } catch (err: unknown) {
        if (err instanceof Error) {
          setSaveErrorToastId(
            genericErrorToast('Error saving quote', err.message, true),
          );
        }
      }
      setIsSaving(false);
    },
    [
      quoteUUID,
      clearToast,
      saveErrorToastId,
      form.getValues,
      queryClient,
      genericSuccessToast,
      genericErrorToast,
      linePropMap,
      currentSite,
      form.setValue,
    ],
  );

  const handlePartRemove = useCallback(
    async (index: number) => {
      if (!activeQuote?.id) return;
      const { lineItems } = form.getValues();
      const li = lineItems[index];
      try {
        if (li.id) {
          await quoteService.deleteQuoteLineItem(activeQuote?.id, li.id);
        }
        removeLineItem(index);
        // TODO: clear validation toasts for this line item
        reassignLineNumbers();
        onSubmit(true);
      } catch (err) {
        if (err instanceof Error) {
          genericErrorToast('Error removing line item', err.message);
        }
      }
    },
    [
      activeQuote,
      removeLineItem,
      onSubmit,
      reassignLineNumbers,
      form.getValues,
      genericErrorToast,
    ],
  );

  // Use this for validation
  const handleFormSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  const handlePDFPreview = async () => {
    setIsLoading(true);
    // there's a chicken or the egg situation here since saving replaces the
    // quote with the api return, which means it needs to be revalidated &&
    // the data rendered in pdf (not the case)

    // NOTE(bb): react-pdf does an internal deep comparison on the prev
    //  document tree's props to only update changed parts of the pdf. But the
    //  deep comparison has a bug (amongst multiple) causing array changes to not be picked up at
    //  all reliably. The only workaround I've found to work consistently is
    //  first update to an empty doc to reset the "prev" internal ref, and then
    //  our actual update which will now always fully re-render
    updatePdfInstance(<Document />);
    updatePdfInstance(
      <QuotePdfDocument
        {...{
          quoteForm: form.getValues(),
          user,
          currentSite,
          linePropMap,
        }}
      />,
    );

    const isValid = await form.trigger();
    // open the modal regardless since currently that's the only way to
    // send to visual and its possible we still would be able to
    onPDFPreviewOpen();

    if (isValid) {
      try {
        toast.closeAll();
        await onSubmit();
      } catch (err) {
        console.error(err);
      }
    } else {
      genericWarningToast(
        'Please fix the errors indicated in the quote builder before sending to ERP',
      );
    }
    setIsLoading(false);
  };

  const handleSendQuoteToCustomer = async () => {
    if (!activeQuote) {
      return genericErrorToast('No active quote');
    }
    if (!pdfInstance.blob) {
      return genericErrorToast('No PDF available to send to customer');
    }

    const { sendToCustomer } = form.getValues();
    setIsLoading(true);
    try {
      const result = await quoteService.sendQuote(
        activeQuote.id,
        pdfInstance.blob,
        sendToCustomer ?? false,
        form.getValues(),
      );

      /**
       * Invalidate the query cache (lazily) so that updates to this quote will
       * be reflected in the quote queue.
       *
       * @TODO(shawk): remove this once we move the mutations in quote builder
       * to react-query, and make sure that those RQ mutations invalidate the
       * necessary cache keys.
       */
      queryClient.invalidateQueries({
        queryKey: quoteQueueSearchQueryKeyPrefix,
      });

      setIsLoading(false);
      genericSuccessToast(
        `Quote ${activeQuote.quoteId} sent${
          sendToCustomer ? ' to customer' : ''
        }!`,
        undefined,
        false,
        true,
      );

      if (result.requiresFeedback) {
        navigate(route(quoteFeedback, { id: activeQuote.id }));
      } else {
        navigate(route(quoteQueue));
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        genericErrorToast('Failed to send quote to customer', err.message);
      }
      setIsLoading(false);
    }
  };

  const handleResetQuote = async () => {
    if (!quoteUUID) return;
    try {
      await quoteService.deleteQuote(quoteUUID);

      /**
       * Invalidate the query cache (lazily) so that updates to this quote will
       * be reflected in the quote queue.
       *
       * @TODO(shawk): remove this once we move the mutations in quote builder
       * to react-query, and make sure that those RQ mutations invalidate the
       * necessary cache keys.
       */
      queryClient.invalidateQueries({
        queryKey: quoteQueueSearchQueryKeyPrefix,
      });

      handleReset();
      navigate(route(quoteNew), { replace: true });
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        console.error(err.message);
      }
    }
  };

  const PageHeaderSubtitle = () => (
    <HStack spacing="24px">
      <Box>Quote ID: {activeQuote?.quoteId}</Box>
      <Box>
        <QuoteStatusBadge status={activeQuote?.status} />
      </Box>
      {activeQuote?.startDate ? (
        <Box>Sent {monthDayYear(activeQuote.startDate)}</Box>
      ) : null}
    </HStack>
  );
  const curQuote = form.getValues();
  if (!activeQuote || !curQuote) {
    return <Loading />;
  }
  return (
    <Box width="100%" minHeight="100%" maxWidth="100%" overflowX="hidden">
      <FormProvider {...form}>
        <form
          // Chrome hack
          autoComplete="do-not-autofill"
          onSubmit={form.handleSubmit(handleFormSubmit)}
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            alignItems: 'stretch',
            flexDirection: 'column',
          }}
        >
          <PageHeader
            title={qb_i18n('title')}
            subtitle={<PageHeaderSubtitle />}
          >
            <HStack gap={2}>
              {!ImmutableQuoteStates.includes(activeQuote.status) && (
                <DeleteConfirmButton
                  name="Reset Quote"
                  description="Please confirm you would like to reset this quote. It will be permanently deleted."
                  onConfirm={handleResetQuote}
                  marginLeft={2}
                  bg="mw.lightGrey"
                  color="white"
                  _hover={{ bg: 'mw.darkGrey' }}
                  borderRadius="0px"
                  title="Reset Quote"
                />
              )}
            </HStack>
          </PageHeader>

          <Box flexGrow={1}>
            <>
              <Spacer borderBottom="1px solid #eee" mb={4} />
              {/* Customer Header */}
              <CustomerHeader
                customerInfo={activeQuote.customerHeader}
                isNewCustomer={activeQuote.customer === null}
                notes={activeQuote.notes}
              />

              <Spacer borderBottom="1px solid #eee" mb={4} mt={5} />

              {/* Parts */}
              <Box marginTop={5}>
                {quoteLineFields.map((lineItem, index) => {
                  // TODO(bb): This is why we had the dup part validation,
                  //  should be able to key by lineItem.id or might be okay to share though
                  const props = linePropMap[lineItem.partId];
                  if (!props) {
                    genericErrorToast(
                      'Unexpected error adding line: missing line xprops',
                    );
                    return null;
                  }
                  return (
                    <QuoteLineContextProvider
                      index={index}
                      onDelete={handlePartRemove}
                      setIsSaving={setIsSaving}
                      key={lineItem.id}
                      baseProps={props}
                    >
                      <QuoteLineItem />
                    </QuoteLineContextProvider>
                  );
                })}
              </Box>
              <Flex>
                <Button
                  onClick={() => showAddPartModal()}
                  marginLeft={'auto'}
                  fontFamily="navigationItem"
                  bgColor="mw.yellow"
                  color="black"
                  borderRadius={'0px'}
                  _hover={{ bg: 'mw.darkYellow' }}
                  gap={2}
                >
                  <FontAwesomeIcon icon={faPlus} fontSize={12} />
                  {qb_i18n('addPart')}
                </Button>
              </Flex>
            </>
          </Box>
          <Spacer borderBottom="1px solid #eee" mb={4} mt={5} />
          <PageFooter>
            <Box
              display={'flex'}
              width="100%"
              justifyContent="space-between"
              flexDirection={'row'}
            >
              <Flex alignContent={'start'} gap={2} justifyContent={'start'}>
                {isSaving && <Loading />}
              </Flex>
              <Flex
                alignContent={'end'}
                direction={'row'}
                gap={2}
                flexGrow={1}
                justifyContent={'end'}
              >
                <Button
                  marginLeft={2}
                  onClick={() => onSubmit()}
                  gap={2}
                  bgColor="#0b7078"
                  color="white"
                  _hover={{ bg: '#046068' }}
                  borderRadius={'0px'}
                  title={qb_i18n('saveQuote')}
                >
                  <FontAwesomeIcon
                    icon={faSave}
                    fontSize={12}
                    aria-label={qb_i18n('saveQuote')}
                  />
                  {qb_i18n('saveQuote')}
                </Button>
                <Button
                  marginLeft={2}
                  isDisabled={!quoteLineFields.length || !QuotePdfDocument}
                  onClick={handlePDFPreview}
                  gap={2}
                  bgColor="#0b7078"
                  color="white"
                  _hover={{ bg: '#046068' }}
                  borderRadius={'0px'}
                  title="Preview PDF"
                >
                  <FontAwesomeIcon
                    icon={faFilePdf}
                    fontSize={12}
                    aria-label="Preview PDF"
                  />
                  Preview PDF
                </Button>
              </Flex>
            </Box>
          </PageFooter>
          <QuotePDFModal
            pdfInstance={pdfInstance}
            onPDFPreviewClose={onPDFPreviewClose}
            isPDFPreviewOpen={isPDFPreviewOpen}
            handleSendQuoteToCustomer={handleSendQuoteToCustomer}
            isLoading={isLoading}
            isSaving={isSaving}
          />
          {/* End of formable scope */}
        </form>
      </FormProvider>
    </Box>
  );
};
