export type QuotePdfTemplateConfig = {
  showQuantityOnHand: boolean;
  showDrawing: boolean;
  showPartDescription: boolean;
  isRAF: boolean;
};

export const defaultConfig: QuotePdfTemplateConfig = {
  showQuantityOnHand: false,
  showDrawing: true,
  showPartDescription: true,
  isRAF: false,
} as const;
